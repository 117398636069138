import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="delete-image"
export default class extends Controller {
  static targets = ["input", "item"];

  delete(e) {
    e.preventDefault();
    this.itemTarget.classList.add("deleted");
    this.inputTarget.disabled = true;
  }

  restore(e) {
    e.preventDefault();
    this.itemTarget.classList.remove("deleted");
    this.inputTarget.disabled = false;
  }
}
