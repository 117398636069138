import { Controller } from "@hotwired/stimulus";
import Splide from "@splidejs/splide";

// Connects to data-controller="slider"
export default class extends Controller {
  static values = {
    pagination: { type: Boolean, default: true },
    type: { type: String, default: "loop" },
    speed: { type: Number, default: 1200 },
    perPage: { type: Number, default: 1 },
    carousel: { type: Boolean, default: false },
    arrows: { type: Boolean, default: false },
    ratio: Number,
  };

  connect() {
    let options = {
      type: this.typeValue,
      arrows: this.arrowsValue,
      pauseOnHover: false,
      autoplay: true,
      interval: 4000,
      speed: this.speedValue,
      pagination: this.paginationValue,
      rewind: true,
    };
    if (this.carouselValue) {
      options.perPage = this.perPageValue;
      options.perMove = this.perPageValue - 1;
    }
    if (this.hasRatioValue) {
      options.heightRatio = this.ratioValue;
    }
    this.slider = new Splide(this.element, options);
    this.slider.mount();
  }

  disconnect() {
    this.slider.destroy();
  }
}
