import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

// Connects to data-controller="autosubmit"
export default class extends Controller {
  static targets = ["form"];

  searchOnSelectChange() {
    clearTimeout(this.submitTimer);
    this.submitTimer = setTimeout(() => {
      Turbo.navigator.submitForm(this.formTarget);
    }, 1000);
  }
}
