import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sensitiveData", "eyeIcon"];

  connect() {
    this.loadVisibility();
  }

  toggle(event) {
    event.preventDefault();
    this.sensitiveDataTargets.forEach(element => {
      element.classList.toggle("hidden-data");
    });
    this.eyeIconTarget.classList.toggle("eye-data-gray");
    this.saveVisibility();
  }

  loadVisibility() {
    const cookie = document.cookie.split(";").find(cookie => cookie.trim().startsWith("sensitiveDataVisibility="));
    if (cookie) {
      const visibilityState = cookie.split("=")[1];
      if (visibilityState === "hidden") {
        this.sensitiveDataTargets.forEach(element => {
          element.classList.add("hidden-data");
        });
        this.eyeIconTarget.classList.add("eye-data-gray");
      } else if (visibilityState === "visible") {
        this.sensitiveDataTargets.forEach(element => {
          element.classList.remove("hidden-data");
        });
        this.eyeIconTarget.classList.remove("eye-data-gray");
      }
    }
  }

  saveVisibility() {
    const visibilityState = this.sensitiveDataTargets[0].classList.contains("hidden-data") ? "hidden" : "visible";
    document.cookie = `sensitiveDataVisibility=${visibilityState}`;
  }
}
