import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="customers"
export default class extends Controller {
  static targets = [
    "countrySelect",
    "sdiSelect",
    "invoiceField",
    "sdiField",
    "pecField",
    "provinceSelect",
    "provinceInput",
  ]
  connect() {
    this.toggleinvoiceFields();
  }

  onCountryChange() {
    this.toggleinvoiceFields();
  }

  toggleinvoiceFields() {
    const selectedOption = this.countrySelectTarget.options[this.countrySelectTarget.selectedIndex];
    const dataCode = selectedOption.getAttribute('code');
    console.log("dataCode", dataCode)
    if (dataCode && dataCode.toLowerCase() == "it") {
      this.invoiceFieldTargets.forEach((t) => t.classList.remove("hide"));
      this.toggleSdiFlieds();
    } else {
      this.invoiceFieldTargets.forEach((t) => t.classList.add("hide"));
    }
    this.toggleProvinceFields();
  }

  toggleSdiFlieds() {
    if (this.sdiSelectTarget.value == "sdi") {
      this.sdiFieldTarget.classList.remove("hide");
      this.pecFieldTarget.classList.add("hide");
    } else if (this.sdiSelectTarget.value == "pec") {
      this.sdiFieldTarget.classList.add("hide");
      this.pecFieldTarget.classList.remove("hide");
    } else {
      this.sdiFieldTarget.classList.add("hide");
      this.pecFieldTarget.classList.add("hide");
    }
  }

  toggleProvinceFields() {
    if (this.countrySelectTarget.value.toLowerCase() == "it") {
      this.provinceSelectTarget.classList.remove("hide");
      this.provinceSelectTarget.querySelector("select").disabled = false;
      this.provinceInputTarget.classList.add("hide");
      this.provinceInputTarget.disabled = true;
    } else {
      this.provinceSelectTarget.classList.add("hide");
      this.provinceSelectTarget.querySelector("select").disabled = true;
      this.provinceInputTarget.classList.remove("hide");
      this.provinceInputTarget.disabled = false;
    }
  }
}
