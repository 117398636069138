import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="link"
export default class extends Controller {
  static values = { path: String, redirect: String };

  connect() {
    if (this.hasRedirectValue) {
      Turbo.visit(this.redirectValue);
    }
  }
}
