import { Turbo } from "@hotwired/turbo-rails";

const postToUrl = (path, params = {}, method = "post") => {
  let form = document.createElement("form");
  form.setAttribute("method", method);
  form.setAttribute("action", path);

  Object.entries(params).forEach(([name, value]) => {
    let hiddenField = document.createElement("input");
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("name", name);
    hiddenField.setAttribute("value", value);
    form.appendChild(hiddenField);
  });

  Turbo.navigator.submitForm(form);
};

const randomUuid = () => {
  const str = (
    Date.now().toString(16) +
    Math.random().toString(16).slice(2) +
    Math.random().toString(16).slice(2) +
    Math.random().toString(16).slice(2)
  ).slice(0, 32);
  return (
    str.slice(0, 8) +
    "-" +
    str.slice(8, 12) +
    "-" +
    str.slice(12, 16) +
    "-" +
    str.slice(16, 20) +
    "-" +
    str.slice(20)
  );
};

const randomNumber = () => {
  return Math.random().toString().slice(2);
};

export { postToUrl, randomUuid, randomNumber };
