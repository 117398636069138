import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="accordion"
export default class extends Controller {
  static targets = ["item", "content"];

  toggle(event) {
    let content = event.currentTarget.nextElementSibling;
    if (event.currentTarget.parentElement.classList.contains("active")) {
      // if current accordion is active, close it
      event.currentTarget.parentElement.classList.remove("active");
      content.style.maxHeight = null;
    } else {
      // close other accordions
      this.itemTargets.map((target) => {
        target.classList.remove("active");
        target.querySelector(".accordion-content").style.maxHeight = null;
      });
      // open the just clicked accordion
      event.currentTarget.parentElement.classList.add("active");
      content.style.maxHeight = `${content.scrollHeight}px`;
    }
  }
}
