import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

// Connects to data-controller="scrollto"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      let diff = $("#home-slider").height() - $(window).height();
      if (diff > 0) {
        diff = diff + 40;
        $(this.element).css({ bottom: diff });
        $(this.element).fadeIn();
      }
    }, 500);
  }
  scrollHome() {
    const scrollPosition = $(window).height() * 0.8;
    $("html, body").animate({ scrollTop: scrollPosition }, 600, "linear");
  }
}
