import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="cart-link"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.element.classList.remove("active");
    }, 2000);
  }
}
