import { Controller } from "@hotwired/stimulus";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

// Connects to data-controller="select"
export default class extends Controller {

  initialize() {
  }

  connect() {
    tippy(this.element, {
      content: this.element.getAttribute('data-tippy-content'),
    });
  }

  disconnect() {
  }
}
