import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="menu"
export default class extends Controller {
  static targets = ["menu"];

  openMenu(e) {
    e.preventDefault();
    this.menuTarget.classList.add("open");
  }

  closeMenu(e) {
    e.preventDefault();
    this.menuTarget.classList.remove("open");
  }
}
