import { DirectUpload } from "@rails/activestorage";

class Uploader {
  constructor(file, url, attachUrl, elementId, blobType) {
    // blobType could be "blob_id" or "signed_id"
    this.attachUrl = attachUrl;
    this.blobType = blobType;
    this.element = document.getElementById(elementId);
    this.blobInputElement = document.getElementById(`blob-${elementId}`);
    this.upload = new DirectUpload(file, url, this);
  }

  start(that) {
    const element = this.element;
    this.upload.create((error, blob) => {
      if (error) {
        // Handle the error
        console.log(error);
        element.classList.add("with-error");
        element.querySelector(".progress").remove();
      } else {
        if (this.blobType == "blob_id") {
          this.blobInputElement.value = blob.id;
        } else if (this.blobType == "signed_id") {
          this.blobInputElement.value = blob.signed_id;
        }
        element.classList.remove("skip-sort");
        element.querySelector(".progress").remove();
        that.onUploadComplete();
      }
    });
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", (event) => this.directUploadDidProgress(event));
  }

  directUploadDidProgress(event) {
    const progress = ((event.loaded / event.total) * 100).toFixed();
    this.element.querySelector(".progress-bar").style.width = `${progress}%`;
  }
}

export default Uploader;
