import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="filters"
export default class extends Controller {
  static targets = ["checkbox", "submitButton"];

  connect() {
    this.toggleclearButton();
  }

  toggleclearButton() {
    // this function need to display the link "clear filters" on bottom
    for (let i = 0; i < this.checkboxTargets.length; i++) {
      if (this.checkboxTargets[i].checked) {
        document.getElementById("clear-filters-target").classList.remove("hide");
        return;
      }
    }
    document.getElementById("clear-filters-target").classList.add("hide");
  }

  submitOnChange() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.submitButtonTarget.click();
    }, 500);
  }
}
