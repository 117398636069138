import { Controller } from "@hotwired/stimulus";
import { randomNumber } from "../lib/helpers";
import Uploader from "../lib/uploader";

// Connects to data-controller="return-images-upload"
export default class extends Controller {
  static filesTotal = 0;
  static filesUploaded = 0;
  static targets = ["previewResultsContainer", "previewResultsItems", "input", "addButton", "formButton"];
  static values = {
    attachUrl: { type: String, default: "" },
  };

  openDialog(e) {
    e.preventDefault();
    this.inputTarget.click();
  }

  onFileChange() {
    this.formButtonTarget.disabled = true;
    this.formButtonTarget.classList.remove("hide");
    this.previewResultsContainerTarget.classList.remove("empty");
    if (this.hasAddButtonTarget) {
      this.addButtonTarget.remove();
    }

    const inputName = this.inputTarget.name;

    if (this.overwriteValue) {
      this.previewResultsItemsTarget.innerHTML = "";
    }

    this.filesTotal = Array.from(this.inputTarget.files).length;
    this.filesUploaded = 0;

    Array.from(this.inputTarget.files).forEach((file) => {
      const elementId = randomNumber();
      // create the empty container where the image will be insert
      this.previewResultsItemsTarget.insertAdjacentHTML(
        "beforeend",
        `<div class="form-file-preview-item skip-sort" id="${elementId}" data-controller="delete-image" data-delete-image-target="item">
          <div class="progress">
            <div class="progress-bar progress-bar-striped active"></div>
          </div>
          <input type="hidden" name="${inputName}" id="blob-${elementId}" data-delete-image-target="input">
          <button class="button button-extrasmall button-delete" data-action="delete-image#delete">Elimina</button>
          <button class="button button-extrasmall button-restore" data-action="delete-image#restore">Ripristina</button>
        </div>`,
      );
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          // convert image file to base64 string and add it to element
          const element = document.getElementById(elementId);
          element.insertAdjacentHTML("afterbegin", `<img src="${reader.result}">`);
        },
        false,
      );
      // don't know what it is for but without it the above code doesn't work
      reader.readAsDataURL(file);
      this.uploadFile(file, elementId);
    });

    // don't pass useless data to the server
    this.inputTarget.value = "";
  }

  uploadFile(file, elementId) {
    const url = this.inputTarget.dataset.directUploadUrl;
    const upload = new Uploader(file, url, this.attachUrlValue, elementId, "signed_id");
    const that = this;
    upload.start(that);
  }

  onUploadComplete() {
    this.filesUploaded += 1;
    if (this.filesUploaded == this.filesTotal) {
      this.formButtonTarget.disabled = false;
    }
  }
}
