import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import select2 from "select2";
import "select2/dist/css/select2.css";

// Connects to data-controller="select"
export default class extends Controller {
  static values = {
    url: String,
    addUrl: String,
    addFrame: String,
    placeholder: { type: String, default: "..." },
    clearable: { type: Boolean, default: false },
  };

  initialize() {
    select2($);
  }

  connect() {
    // For single selects only, in order for the placeholder value to appear, you must have a blank <option> as the first option in your <select> control.
    // This is because the browser tries to select the first option by default.
    // If your first option were non-empty, the browser would display this instead of the placeholder
    const el = this.element;
    if (this.hasUrlValue) {
      const url = this.urlValue;
      $(el).select2({
        placeholder: this.placeholderValue,
        minimumInputLength: 3,
        ajax: {
          url: url,
          dataType: "json",
          method: "POST",
          delay: 500,
          data: (params) => {
            return { q: params.term };
          },
          processResults: (data) => {
            return {
              results: data,
            };
          },
        },
      });
    } else {
      $(el).select2({
        minimumResultsForSearch: 10,
        placeholder: this.placeholderValue,
        allowClear: this.clearableValue,
      });
    }

    $(el).on("select2:open", () => {
      // Focus search input
      document.querySelector(".select2-search__field").focus();
      // Skip this callback if there aren't these values
      if (!(this.hasAddUrlValue && this.hasAddFrameValue)) {
        return;
      }
      if ($(".select2-dropdown").find(".select2-dropdown-link").length > 0) {
        // Skip if already exist otherwise will be duplicated
        return;
      }
      $(".select2-dropdown")
        .append(
          `<a class="select2-dropdown-link" data-turbo-frame="${this.addFrameValue}" href="#" >Aggiungi elemento</a>`,
        )
        .on("click", (e) => {
          // Get value of the search input inside a dropdown
          const searchValue = document.getElementsByClassName("select2-search__field")[0].value;
          const url = `${this.addUrlValue}?v=${encodeURIComponent(searchValue)}`;
          // Select2 lock scrolling when select is open, removing this event the scroll is enabled again
          const evt = "scroll.select2";
          $(el).parents().off(evt);
          $(window).off(evt);
          // Change link path to render correct frame
          e.target.href = url;
        });
    });

    // Select2 doesn't fire native events and we must force it using an event listener
    $(el).on("select2:select", () => {
      let event = new Event("change", { bubbles: true });
      el.dispatchEvent(event);
    });
    $(el).on("select2:clearing", () => {
      let event = new Event("change", { bubbles: true });
      el.dispatchEvent(event);
    });
  }

  disconnect() {
    const el = this.element;
    $(el).select2("destroy");
  }
}
