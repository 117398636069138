import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

// Connects to data-controller="cart"
export default class extends Controller {
  connect() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      Turbo.navigator.submitForm(this.formTarget);
    }, 500);
  }
}
