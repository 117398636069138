import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

// Connects to data-controller="add-to-cart-box"
export default class extends Controller {
  static targets = ["input", "spinner", "check"];
  static values = {
    removeCheck: { type: Boolean, default: false },
    permittedMin: { type: Number, default: 1 },
  };

  connect() {
    if (this.removeCheckValue) {
      setTimeout(() => {
        $(this.checkTarget).fadeOut();
      }, 2000);
    }
  }

  increaseQuantity(e) {
    e.preventDefault();
    this.inputTarget.value = parseInt(this.inputTarget.value) + 1;
  }

  decreaseQuantity(e) {
    e.preventDefault();
    if (parseInt(this.inputTarget.value) <= this.permittedMinValue) {
      return;
    }
    this.inputTarget.value = parseInt(this.inputTarget.value) - 1;
  }

  showSpinner() {
    this.submitTimer = setTimeout(() => {
      this.spinnerTarget.classList.remove("hide");
    }, 500);
  }

  checkQuantity(e) {
    let sum = 0;
    const elements = document.getElementsByClassName("quantity-input");
    for (var i = 0; i < elements.length; i++) {
      sum += parseInt(elements[i].value);
    }

    if (sum == 0) {
      // don't submit form if total quantity is 0
      e.preventDefault();
    } else {
      this.showSpinner();
    }
  }
}
