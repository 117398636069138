import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

// Connects to data-controller="cart-row"
export default class extends Controller {
  static targets = ["input", "destroyField"];

  increaseQuantity(e) {
    e.preventDefault();
    this.inputTarget.value = parseInt(this.inputTarget.value) + 1;
    this.submitForm();
  }

  decreaseQuantity(e) {
    e.preventDefault();
    if (parseInt(this.inputTarget.value) <= 1) {
      return;
    }
    this.inputTarget.value = parseInt(this.inputTarget.value) - 1;
    this.submitForm();
  }

  onQuantityChange() {
    this.submitForm();
  }

  submitForm() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      document.getElementById("form-stram-input").value = 1;
      Turbo.navigator.submitForm(document.getElementById("cart-form"));
    }, 800);
  }

  confirmDestroy(e) {
    e.preventDefault();
    if (confirm("Vuoi cancellare il prodotto?")) {
      this.destroyFieldTarget.value = 1;
      this.submitForm();
    }
  }
}
