import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="toggle-filters"
export default class extends Controller {
  static targets = ["filtersContainer"];

  toggleFilters(event) {
    event.preventDefault();
    const content = document.getElementById("mobile-filters-container");
    content.classList.toggle("active");
  }
}
